<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <div v-if="invoiceSettings">
      <router-link :to="{ name: 'settings-home' }" icon='arrowdownlarge' class="is-back"><p><i class="backarrow"></i>BACK</p></router-link>
      <h1>Invoice Settings</h1>
      <p>Configure settings related to how your business invoices customers.</p>
      <hr>
      <b-field class="modal-label" label="Additional Payment Instructions" message="Provide details of how to pay with bank accounts, cheques, your PayPal account, etc. This will appear on any invoice you send.">
        <b-input type="textarea" v-model="invoiceSettings.additionalPaymentInstructions" placeholder="Account Number: 123456789"/>
      </b-field>
<!--      <b-field message="These account details will be displayed on your invoices." class="modal-label" label="Client Deposit Bank Account" v-if="bankAccounts">-->
<!--        <b-select v-model="invoiceSettings.bankAccountId" placeholder="Select Bank Account">-->
<!--          <option-->
<!--            v-for="bankAccount in bankAccounts"-->
<!--            :value="bankAccount.id"-->
<!--            :key="bankAccount.id">-->
<!--            {{ bankAccount.label }}-->
<!--          </option>-->
<!--        </b-select>-->
<!--      </b-field>-->
<!--      <b-message type="is-warning" v-else>-->
<!--        <strong>You should create a bank account before configuring invoice settings.</strong>-->
<!--      </b-message>-->
      <b-field class="modal-label" label="Default Payment Terms">
        <b-select v-model="invoiceSettings.defaultPaymentTerms" placeholder="Select Payment Terms">
          <option :value="7">7 Days</option>
          <option :value="14">14 Days</option>
          <option :value="30">30 Days</option>
          <option :value="90">90 Days</option>
        </b-select>
      </b-field>
      <b-field class="modal-label" label="Default Tax Rate">
        <b-select v-model="invoiceSettings.defaultTaxRateCode" placeholder="Select Tax Rate">
          <option v-for="(taxRate, index) in taxRates" v-bind:key="index" :value="taxRate.code">{{ taxRate.name }}</option>
        </b-select>
      </b-field>
      <b-field class="modal-label" label="Multi-currency Invoicing">
        <b-select v-model="invoiceSettings.useMultipleCurrencies" placeholder="Multi-currency options...">
          <option :value="false">Only use my business' primary currency.</option>
          <option :value="true">Allow me to change the currency on an invoice.</option>
        </b-select>
      </b-field>
      <hr>
      <div class="level is-mobile">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item">
            <a class="button is-light" @click="cancel">Cancel</a>
          </div>
          <div class="level-item">
            <button class="button is-primary" @click="save">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'InvoiceSettings',
  computed: {
    taxRates () {
      return this.$store.getters['taxRates/taxRates']
    }
  },
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Invoice Settings', route: null }
      ],
      isLoading: false,
      invoiceSettings: null
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'settings-home' })
    },
    reloadInvoiceSettings () {
      this.isLoading = true
      this.$store.dispatch('settings/fetchInvoiceSettings')
        .then((invoiceSettings) => {
          this.invoiceSettings = invoiceSettings
          this.isLoading = false
        })
    },
    save () {
      console.log('this.invoiceSettings', this.invoiceSettings)
      this.$store.dispatch('settings/saveInvoiceSettings', this.invoiceSettings)
        .then((response) => {
          this.$store.dispatch('account/updateUseMultipleCurrencies', response.useMultipleCurrencies)
        })
        .catch(err => {
          const errors = err.response.data.errors
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              this.$buefy.toast.open({
                message: errors[errorKey][0],
                type: 'is-danger',
                duration: 4000,
                queue: false
              })
            })
          } else {
            this.$buefy.toast.open({
              message: `Error: ${err.message}`,
              type: 'is-danger',
              duration: 4000
            })
          }
        })
        .then(() => {
          this.$buefy.toast.open({
            message: 'Invoice settings saved.',
            type: 'is-success'
          })
          this.$router.push({ name: 'settings-home' })
        })
    }
  },
  mounted () {
    // this.$store.dispatch('settings/fetchBankAccounts')
    this.$store.dispatch('taxRates/fetch')
    this.reloadInvoiceSettings()
  },
  components: { Breadcrumbs }
}
</script>
